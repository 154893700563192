import React from 'react';
import Section from 'components/HOC/Section';

const IconBoxItemOne = ({data, border, padding, iconType, className, showBtn}) => {
    return (
        <Section id="aboutUs">
            <div
                className={`${className} icon-box mt--30 text-center ${border ? '' : 'no-border'} ${!border && padding ? 'with-padding' : ''}`}>
                <div className="inner">
                    <div className="content">
                        <h5 className="heading heading-h5">About Us</h5>
                        <p className="bk_pra">At Skylight Service,  
                        we understand that navigating the complexities of loans 
                        can be daunting. 
                        Whether you're seeking a personal loan, 
                        a mortgage, or financing for your business, 
                        we're here to simplify the process and 
                        find the best solutions tailored to your needs. 
                        Our team of specialists is dedicated to guiding you 
                        through every stage of the loan process, 
                        from your initial inquiry to the completion of the final paperwork. 
                        We prioritize keeping you well-informed at every step, 
                        empowering you to make decisions that align with your goals and interests.</p>
                    </div>
                </div>
            </div>
        </Section>
        
    );
};

export default IconBoxItemOne;