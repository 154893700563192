import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import IconListItem from "components/icon-list/IconListItemOne";
import Section from 'components/HOC/Section';

const AboutPageArea2 = () => {
    return (
        <Section id="services">
        <div className="brook-feature-area ptb--150 ptb_md--80 ptb_sm--60 bg_color--1">
            <Container>
                <Row>
                    <Col>
                        <div className="brook-section-title mb--10">
                            <h3 className="heading heading-h3">Our Services</h3>
                        </div>

                        <Row>
                            {content.map(item => (
                                <Col md={6} className="mt--50" key={item.id}>
                                    <IconListItem data={item}/>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
        </Section>
    );
};

const content = [
    {
        id: 1,
        iconClass: "ion-ios-home",
        title: "Personal Loans",
        content: "Whether you're consolidating debt, covering unexpected expenses, or funding a major purchase, our personal loan options provide flexible solutions tailored to your financial needs."
    },
    {
        id: 2,
        iconClass: "ion-ios-infinite",
        title: "Mortgages",
        content: "Buying a home is a significant milestone, and our mortgage services are designed to streamline the process. From pre-approval to closing, we offer expert guidance and competitive rates to help you secure your dream home."
    },
    {
        id: 3,
        iconClass: "ion-clock",
        title: "Short-Term Lending",
        content: "When you're facing a financial emergency, time is of the essence. Our short-term loans offer fast approval and funding, ensuring you bridge finance settled quickly"
    },
    {
        id: 4,
        iconClass: "ion-ios-people",
        title: "Credit Counseling",
        content: "In addition to loan facilitation, we offer credit counseling services to help you manage your finances effectively. Our experienced advisors provide personalized guidance on budgeting, credit repair, and debt management, setting you on the path to financial stability."
    },
]

export default AboutPageArea2;
