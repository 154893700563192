import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import PageContainer from "container/page";
import Header from "components/header/HeaderTwo";
import PopupMobileMenu from "components/popup/PopupMobileMenu";
import Breadcrumb from "components/breadcrumb/BreadcrumbFive";
import IconBoxes from "container/icon-box/pages/IconBoxOne";
import Features from "container/about-us/AboutPageArea2";
import Contact from "container/contact/ContactOne";
import Footer from "container/footer/FooterThree";

const AboutPageFour = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Skylight Service</title>
            </Helmet>

            <PageContainer classes="template-color-1 template-font-1" revealFooter={false}>
                <Header/>
                <PopupMobileMenu/>
                <Breadcrumb
                    title={"SKYLIGHT SERVICE PTY LTD"}
                />
                <IconBoxes/>
                <Features/>
                <Contact/>
                <Footer/>
            </PageContainer>
        </Fragment>
    );
};

export default AboutPageFour;